function passBody(data) {
    let formBody = [ ];
    for(const property in data){
        formBody.push(encodeURIComponent(property) + "=" + encodeURIComponent(data[property]));
    }
    formBody = formBody.join("&");
    return formBody;
}

export function APIFetchRaw(url, data){
    return new Promise((resolve, reject) => {
        fetch('https://orlen.ampersoft.cz/api/' + url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: passBody(data)
        }).then(x => x.json()).then(resolve).catch(reject);
    });
}

export function APIFetch(url, data){
    return new Promise((resolve, reject) => {
        fetch('https://orlen.ampersoft.cz/api/' + url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: passBody(data)
        }).then(x => x.json()).then(resolve).catch(reject);
    });
}