import { Suspense, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { APIFetch } from "../../Helpers/apiFetch"
import { UserContext } from "../../Routes"
import { useContext } from "react"
import { PageMenu, PageMenuBlock } from "../../Components/PageMenu"
import { AiOutlineRollback } from 'react-icons/ai';
import { useNavigate } from "react-router-dom"

export function Registers() {

    const [pendingRegisters, setPendingRegisters] = useState([])

    const { user, setUser } = useContext(UserContext)

    const GetAllRegistors = () => {
        APIFetch("Admin/Register/GetAll", {
            token: user.tokens.api_token
        }).then(resp => {
            setPendingRegisters(resp.Data)
        })
    }

    useEffect(() => {
        GetAllRegistors()
    }, [])

    const PovolitRegistraci = (email) => {
        APIFetch("Admin/Register/Approve", {
            email: email,
            token: user.tokens.api_token
        }).then(resp => {
            GetAllRegistors()
            toast.success(email + " byl schválen!")
        })
    }

    const SmazatRegistraci = (email) => {
        APIFetch("Admin/Register/Delete", {
            email: email,
            token: user.tokens.api_token
        }).then(resp => {
            GetAllRegistors()
            toast.success(email + " byl smazán!")
        })
    }

    const navigate = useNavigate()

    const goBack = () => {
        navigate("/administrace/")
    }

    return (
        <>
            <PageMenu>
                <PageMenuBlock tippy="Zpátky" onClick={goBack}><AiOutlineRollback size={"30"} /></PageMenuBlock>
            </PageMenu>
            <h1>Administrace</h1>
            <h2>Schválení nových registrací</h2>
            <table>
                <thead>
                    <tr>
                        <th>Email</th>
                        <th>Jméno</th>
                        <th>Příjmení</th>
                        <th>Telefon</th>
                        <th>Datum vytvoření</th>
                        <th>Akce</th>
                    </tr>
                </thead>
                <tbody>
                    {pendingRegisters.map((registor) => (
                        <tr>
                            <td>{registor.email}</td>
                            <td>{registor.firstName}</td>
                            <td>{registor.lastName}</td>
                            <td>{registor.telephone}</td>
                            <td>{registor.created_at}</td>
                            <td>
                                <button type2="def" onClick={() => PovolitRegistraci(registor.email)}>Povolit</button>
                                <button type2="def" onClick={() => SmazatRegistraci(registor.email)}>Smazat</button>
                            </td>

                        </tr>
                    ))}
                    {/* <tr>
                        <td>test@test.com</td>
                        <td>15.5.2023</td>
                        <td>
                            <button type2="def" onClick={() => PovolitRegistraci("test@test.com")}>Povolit</button>
                            <button type2="def" onClick={() => SmazatRegistraci("test@test.com")}>Smazat</button>
                        </td>
                    </tr> */}
                </tbody>
            </table>
        </>
    )
}