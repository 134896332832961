import { BsFillSendCheckFill, BsBoxArrowInLeft } from 'react-icons/bs';
import { BiTimeFive } from "react-icons/bi"
import { FaToiletPaperSlash, FaTrash } from "react-icons/fa"
import { PageMenu, PageMenuBlock } from '../Components/PageMenu';
import { useEffect, useState } from 'react';
import { APIFetch } from '../Helpers/apiFetch';
import { useContext } from 'react';
import { UserContext } from '../Routes';
import Modal from "react-modal"
import { betterDate } from '../Components/betterDate';
import { toast, useToast } from 'react-toastify';
import { MdFormatIndentDecrease } from 'react-icons/md';
import { useNavigate } from "react-router-dom"
import betterState from '../Components/betterState';


export function Revize() {

    const { user, setUser } = useContext(UserContext)

    const [inProgressForms, setInProgressForms] = useState([])
    const [sendedForms, setSendedForms] = useState([])
    const [deletedForms, setDeletedForms] = useState([])
    const [returnedForms, setReturnedForms] = useState([])

    const navigate = useNavigate()


    const GetAll = () => {
        APIFetch("Forms/GetAll", {
            state: "in_progress",
            token: user.tokens.api_token
        }).then(resp => {
            setInProgressForms(resp.Data)
        })

        APIFetch("Forms/GetAll", {
            state: "sended",
            token: user.tokens.api_token
        }).then(resp => {
            setSendedForms(resp.Data)
        })

        APIFetch("Forms/GetAll", {
            state: "deleted",
            token: user.tokens.api_token
        }).then(resp => {
            setDeletedForms(resp.Data)
        })

        APIFetch("Forms/GetAll", {
            state: "returned",
            token: user.tokens.api_token
        }).then(resp => {
            setReturnedForms(resp.Data)
        })
    }

    useEffect(() => {
        GetAll()
    }, [])

    const [selectedFilter, setSelectedFilter] = useState("Rozpracované")

    const handleFilter = (filter) => {
        setSelectedFilter(filter)
        GetAll()
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            width: "500px",
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    const sendRevision = (fromId) => {
        APIFetch("Forms/Send", {
            revision_number: fromId,
            token: user.tokens.api_token
        }).then(resp => {
            console.log(resp)
            GetAll()
            toast.success("Revize byla odeslána")
        })
    }

    const openRevision = (fromId) => {
        navigate("/dashboard/editace/" + fromId)
        setUser({ ...user, isEditing: true })
    }

    const deleteRevision = (formId) => {
        APIFetch("Forms/ChangeState", {
            revision_number: formId,
            state: "deleted",
            token: user.tokens.api_token
        }).then(resp => {
            console.log(resp)
            GetAll()
            toast.info("Revize byla přesunuta do koše")
        })
    }

    const [formIdToDelete, setFormIdToDelete] = useState()

    const deleteRevisonPermanently = (formId) => {
        setModalDRP(true)
        setFormIdToDelete(formId)
    }

    const [modalDRP, setModalDRP] = useState(false)

    const YesSureBox = () => {
        APIFetch("Forms/Delete", {
            revision_number: formIdToDelete,
            token: user.tokens.api_token
        }).then(resp => {
            toast.success("Revize byla natrvalo smazána!")
            GetAll()
            setFormIdToDelete("")
            setModalDRP(false)
        })
    }


    const bringBackRevision = (formId) => {
        APIFetch("Forms/ChangeState", {
            revision_number: formId,
            state: "in_progress",
            token: user.tokens.api_token
        }).then(resp => {
            toast.success("Vaše revize byla je obnovena!")
            toast.info("Vaší revizi najdete ve \"Rozpracovaných\"")
            GetAll()
        })
    }

    const duplicateRevision = (formId) => {
        APIFetch("Forms/Duplicate", {
            revision_number: formId,
            token: user.tokens.api_token
        }).then(resp => {
            toast.success("Revize byla duplikována")
            GetAll()
        })
    }


    return (
        <>
            <Modal isOpen={modalDRP}
                style={customStyles}
                onRequestClose={() => setModalDRP(false)}>
                <p>Vážně chcete natrvalo smazat <b>{formIdToDelete}</b>?</p>
                <div style={{ display: "flex", gap: "10px", alignItems: "center", justifyContent: "center", paddingTop: "10px" }}>
                    <button type2="def" onClick={YesSureBox}>ANO</button>
                    <button type2="def" onClick={() => setModalDRP(false)}>NE</button>
                </div>
            </Modal>
            <PageMenu center>
                <PageMenuBlock tippy="Rozpracované" onClick={() => handleFilter("Rozpracované")}><BiTimeFive size={"30"} /></PageMenuBlock>
                <PageMenuBlock tippy="Odeslané" onClick={() => handleFilter("Odeslané")}><BsFillSendCheckFill size={"30"} /></PageMenuBlock>
                <PageMenuBlock tippy="Vrácené" onClick={() => handleFilter("Vrácené")}><BsBoxArrowInLeft size={"30"} /></PageMenuBlock>
                <PageMenuBlock tippy="Koš" onClick={() => handleFilter("Koš")}><FaTrash size={"30"} /></PageMenuBlock>
            </PageMenu>
            <div className="marginCenter">
                {selectedFilter === "" ? (
                    <h1>Vaše revize</h1>
                ) : (
                    <h1>Vaše revize - {selectedFilter}</h1>
                )}
            </div>

            <table style={{ position: "relative" }}>
                <thead>
                    <tr>
                        <th>Revizní číslo</th>
                        <th>Status revize</th>
                        <th>Vytvořeno</th>
                        <th>Naposledy upraveno</th>
                        {selectedFilter !== "Odeslané" ? <th>Akce</th> : <th>Odesláno</th>}
                    </tr>
                </thead>
                <tbody>
                    {selectedFilter === "Rozpracované" && (
                        inProgressForms.map(form => (
                            <tr>
                                <td>{form.revision_number}</td>
                                <td>{betterState(form.state)}</td>
                                <td>{betterDate(form.created_at)}</td>
                                <td>{betterDate(form.updated_at)}</td>
                                <td>
                                    <button type2="def" onClick={() => openRevision(form.revision_number)}>Otevřít</button>
                                    <button type2="def" onClick={() => sendRevision(form.revision_number)}>Odeslat</button>
                                    <button type2="def" onClick={() => duplicateRevision(form.revision_number)}>Duplikovat</button>
                                    <button type2="def" onClick={() => deleteRevision(form.revision_number)}>Smazat</button>
                                </td>
                            </tr>
                        ))
                    )}
                    {selectedFilter === "Odeslané" && (
                        sendedForms.map(form => (
                            <tr>
                                <td>{form.revision_number}</td>
                                <td>{betterState(form.state)}</td>
                                <td>{betterDate(form.created_at)}</td>
                                <td>{betterDate(form.updated_at)}</td>
                                <td>{betterDate(form.sended_at)}</td>
                            </tr>
                        ))
                    )}
                    {selectedFilter === "Vrácené" && (
                        <>
                            {returnedForms.map(form => (
                                <tr>
                                    <td>{form.revision_number}</td>
                                    <td>{betterState(form.state)}</td>
                                    <td>{betterDate(form.updated_at)}</td>
                                    <td>{betterDate(form.sended_at)}</td>
                                    <td>
                                        <button type2="def" onClick={() => openRevision(form.revision_number)}>Otevřít</button>
                                        <button type2="def" onClick={() => sendRevision(form.revision_number)}>Znovu Odeslat</button>
                                        <button type2="def" onClick={() => deleteRevision(form.revision_number)}>Smazat</button>
                                    </td>
                                </tr>
                            ))}
                        </>
                    )}
                    {selectedFilter === "Koš" && (
                        <>
                            {deletedForms.map(form => (
                                <tr>
                                    <td>{form.revision_number}</td>
                                    <td>{betterState(form.state)}</td>
                                    <td>{betterDate(form.updated_at)}</td>
                                    <td>{betterDate(form.sended_at)}</td>
                                    <td>
                                        <button type2="def" onClick={() => bringBackRevision(form.revision_number)}>Vrátit</button>
                                        <button type2="def" onClick={() => deleteRevisonPermanently(form.revision_number)}>Natrvalo smazat</button>
                                    </td>
                                </tr>
                            ))}
                        </>
                    )}
                </tbody>
            </table>

        </>
    )
}