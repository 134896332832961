import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom"

import { Login } from "./LoginForm/login"
import React, { useEffect, useState } from "react"

import { Dashboard } from "./Dashboard/Dashboard"
import { EditRevision, Revision } from "./Editace/Editace"
import { Profile } from "./profil/profile"
import { Revize } from "./Revize/Revize"
import { Administrace } from "./Administrace/Administrace"
import { Registrace } from "./Registrace/Registrace"
import { DisplayRevisionControl, RevisionControl } from "./Administrace/Pages/RevisionControl"
import { Registers } from "./Administrace/Pages/Registers"

export const UserContext = React.createContext()

export function UserRoutes() {
    const [user, setUser] = useState()

    return (
        <UserContext.Provider value={{user, setUser}}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Navigate to={"/login"} />} />
                    <Route path="/login" element={<Login></Login>} />
                    <Route path="/dashboard" element={<Dashboard></Dashboard>}/>
                    <Route path="/dashboard/editace" element={<Dashboard><Revision/></Dashboard>}/>
                    <Route path="/dashboard/editace/:formId" element={<Dashboard><EditRevision/></Dashboard>}/>
                    <Route path="/dashboard/revize" element={<Dashboard><Revize/></Dashboard>}/>
                    <Route path="/profil/uzivatel" element={<Dashboard><Profile/></Dashboard>}/>
                    <Route path="/administrace" element={<Dashboard><Administrace/></Dashboard>}/>
                    <Route path="/administrace/revize/" element={<Dashboard><RevisionControl/></Dashboard>}/>
                    <Route path="/administrace/registrace" element={<Dashboard><Registers/></Dashboard>}/>
                    <Route path="/administrace/revize/:formId" element={<Dashboard><DisplayRevisionControl/></Dashboard>}/>
                    <Route path="/registrace" element={<Registrace/>}/>
                </Routes>
            </BrowserRouter>
        </UserContext.Provider>
    )
}