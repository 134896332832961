import Modal from 'react-modal';
import { useState } from 'react';

export function RevisionSelector({value, setValue}) {
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    return (
        <>
            <select style={{ width: "100%", fontSize: "18px", maxWidth: "1200px" }} value={value} onChange={(e) => {setValue(e.target.value)}}>
                <option value="">Vyberte...</option>
                <option value="výchozí">Zpráva o výchozí revizi</option>
                <option value="pravidelné">Zpráva o pravidelné revizi</option>
                <option value="periodické">Zpráva o periodické revizi</option>
                <option value="výběrové">Zpráva o výběrové revizi</option>
                <option value="mimořádné">Zpráva o mimořádné revizi</option>
                <option value="souhrné">Zpráva o souhrné revizi</option>
            </select>
        </>
    )
}