import { Link, isRouteErrorResponse } from "react-router-dom"
import { toast } from 'react-toastify';
import { APIFetch } from '../Helpers/apiFetch';
import { useState } from "react";

export function Registrace() {

    const [registor, setRegistor] = useState({email: "", password: "", firstName: "", lastName: "", telephone: ""})

    const register = () => {

        APIFetch("Auth/Register", {
            email: registor.email,
            password: registor.password,
            firstName: registor.firstName,
            lastName: registor.lastName,
            telephone: registor.telephone,
        }).then(resp => {
            authRegister(resp.Data)
        })
        toast.warning("Prosím ověřte si váš email!")
    }

    const authRegister = (token) => {
        APIFetch("Auth/VerifyEmail/" + token).then(resp => {
            console.log(resp)
            toast.success("Váš účet byl ověřen! Nyní vyčkejte případně kontaktujte vašeho administrátora.")
        })
    }

    return (
        <div className='page'>
            <div id="registerBox">
                <form>
                    <div className="marginCenter">
                        <h1>Revizní zprávy</h1>
                        <h2>Registrace</h2>
                    </div>
                    <div>
                        <label>Zadejte E-mail</label>
                        <input value={registor.email} onChange={(e) => setRegistor({...registor, email: e.target.value})} type='email' placeholder="jan@novy.cz" required/>
                        
                        <label>Zadejte heslo</label>
                        <input value={registor.password} onChange={(e) => setRegistor({...registor, password: e.target.value})} type='password' placeholder="***" required/>
                        
                        <label>Zadejte jméno</label>
                        <input value={registor.firstName} onChange={(e) => setRegistor({...registor, firstName: e.target.value})} type='input' placeholder="Jan" required/>
                        
                        <label>Zadejte příjmení</label>
                        <input value={registor.lastName} onChange={(e) => setRegistor({...registor, lastName: e.target.value})} type='input' placeholder="Nový" required/>
                        
                        <label>Zadejte telefon</label>
                        <input value={registor.telephone} onChange={(e) => setRegistor({...registor, telephone: e.target.value})} type='input' placeholder="123456789" required/>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", gap: "10px", justifyContent: "flex-end" }}>
                        <Link to={"/login"}>Máte už účet?</Link>
                        <button type='button' type2="def" onClick={register}>Vytvořit účet</button>
                    </div>
                </form>
            </div>
            <div id="loginBoxMessage">
                <p>Prosím zadejte platné údaje, případně kontaktujte administrátora.</p>
            </div>
        </div>
    )
}