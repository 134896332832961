import { useEffect, useState } from "react";
import { PageMenu, PageMenuBlock } from "../Components/PageMenu";
import { useNavigate } from "react-router-dom";

export function Administrace() {

    const navigate = useNavigate()

    const revize = () => {
        navigate("/administrace/revize")
    }

    const registrace = () => {
        navigate("/administrace/registrace")
    }

    return (
        <>
            <PageMenu big center title={"Administrace"}>
                <PageMenuBlock onClick={registrace}>Schválení nových registrací</PageMenuBlock>
                <PageMenuBlock onClick={revize}>Kontrola revizí</PageMenuBlock>
            </PageMenu>
        </>
    )
}