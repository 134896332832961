import Tippy from '@tippyjs/react';
import React from 'react';
import { createContext, useContext } from 'react';

const PageMenuContext = createContext()

export function PageMenu({ big, children, title, center }) {
    return (
        <div className={big ? "pageMenuBig" : "pageMenuSmall"}>
            {big && (
                <div className='marginCenter'>
                    <h1>{title}</h1>
                </div>
            )}
            <div className={ center ? 'pageMenuBlocks' : 'pageMenuBlocks2'} >
                <PageMenuContext.Provider value={big}>
                    {children}
                </PageMenuContext.Provider>
            </div>
        </div>
    )
}

export function PageMenuBlock({ children, onClick, tippy, text }) {
    const big = useContext(PageMenuContext)
    return (
        <>
            {big ? (
                <div className="pageMenuBlockBig" onClick={onClick}>
                    <p>{children}</p>
                </div>
            ) : (
                <>
                    {text ? (
                        <div className="pageMenuBlockSmall" onClick={onClick}>
                            <p>{children}</p>
                        </div>
                    ) : (
                        <Tippy content={tippy}>
                            <div className="pageMenuBlockSmall" onClick={onClick}>
                                {children}
                            </div>
                        </Tippy>
                    )}
                </>
            )}
        </>
    )
}

export function PageMenuAdditional({ children }) {
    return (
        <div style={{ borderLeft: "1px solid black", paddingLeft: "10px" }}>
            {children}
        </div>
    )
}

export function PageMenuText({ children }) {
    return (
        <p style={{ width: "fit-content", fontWeight: "500" }}>{children}</p>
    )
}