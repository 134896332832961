import { useContext } from "react"
import { UserContext } from "../Routes"

const FormBodyGap = "10px"

export function FormBorder({ children }) {
    const style = {
        border: "1px solid gray",
        display: "flex",
        flexFlow: "column",
        width: "100%",
        maxWidth: "1200px",
        margin: "0 auto"
    }
    return (
        <div style={style}>
            {children}
        </div>
    )
}

export function FormBox({ children, justify, align, back, column, gap, padding, border }) {
    const style = {
        display: "flex",
        flexFlow: column ? "column" : "row",
        alignItems: align ? align : "center",
        width: "100%",
        gap: gap,
        justifyContent: "center",
        backgroundColor: back,
        margin: "0 auto",
        padding: padding,
        border: border ? "1px solid gray" : "none",
        maxWidth: "1200px",
    }
    return (
        <div style={style} className={padding && "formBoxPadding"}>
            {children}
        </div>
    )
}

export function FormLabel({ children, width }) {
    const style = {
        width: width,
        paddingRight: width ? "0" : "5px"
    }
    return (
        <label style={style} className="formLabel">{children}</label>
    )
}
export function FormTitle({ children }) {
    return (
        <label className="formTitle">{children}</label>
    )
}

export function FormInput({ type, fill, value, onChange, width, multiple, style2, disabled }) {
    const {user, setUser} = useContext(UserContext)
    const style = {
        flexGrow: fill ? "1" : "auto",
        width: width
    }
    return <input onClick={() => setUser({...user, isEditing: true})} placeholder="zde pište..." type2="form" multiple={multiple} type={type ? type : "text"} style={style2 ? style2 : style} value={value} onChange={onChange} disabled={disabled} />
}

export function FormTextArea({value, onChange, disabled}) {
    const {user, setUser} = useContext(UserContext)
    return(
        <textarea onClick={() => setUser({...user, isEditing: true})} value={value} onChange={onChange} placeholder="zde pište..." disabled={disabled}/>
    )
}

export function FormSelect({ values, value, onChange, fill, width, fit, disabled}) {
    const {user, setUser} = useContext(UserContext)
    const style = {
        width: width,
        flexGrow: fill ? "1" : "auto"
    }
    return (
        <select onClick={() => setUser({...user, isEditing: true})} style={style} value={value} onChange={onChange} disabled={disabled}>
            <option>---</option>
            {values.map(el => (
                <option>{el}</option>
            ))}
        </select>
    )
}

export function FormHeader({ add, remove, children, disabled }) {
    const style = {
        backgroundColor: "lightGray",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "5px 10px"
    }
    const secondStyle = {
        display: "flex",
        gap: "5px",
    }
    if (!add || disabled) {
        return (
            <div style={style}>
                {children}
            </div>
        )
    } else {
        return (
            <div style={style}>
                <div>
                    {children}
                </div>
                <div style={secondStyle}>
                    <button type2="formAdd" className="printhide" onClick={add}>+</button>
                    <button type2="formRemove" className="printhide" onClick={remove}>-</button>
                </div>
            </div>
        )
    }
}