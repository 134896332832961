import React, { useContext, useEffect, useState } from 'react';
import { useFetcher, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { APIFetch } from '../Helpers/apiFetch';
import "./login.css"
import { UserContext } from '../Routes';
import { Link } from 'react-router-dom';
// import './login.scss';
// import { APIFetch } from '../../Helpers/apiFetch';
// import { UserContext } from '../../routes';

export function Login() {
    const navigate = useNavigate()
    const { user, setUser } = useContext(UserContext)

    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [forms, setForms] = useState()

    const login = (e) => {

        if (email === "a") {
            setUser({ email: "a@a.com", firstName: "Matyáš", lastName: "Schuller", telephone: "775626290", osvc: "false", companies: {}, tokens: { api_token: "1F8uByO3bX0sHEKAVXaM5CJE5bmUOKew" } })
            navigate("/dashboard")
        } else {
            APIFetch("Auth/Login", {
                "email": email,
                "password": password
            }).then(resp => {
                if (resp.Passed) {
                    setUser(resp.Data)
                    navigate("/dashboard")
                    toast.success("Úspěšně přihlášeno!")
                } else {
                    toast.error(resp.Message)
                }
            })
        }
    }

    return (
        <div className='page'>
            <div id="loginBox">
                <form>
                    <div className='marginCenter'>
                        <h1>Revizní zprávy</h1>
                        <h2>Přihlášení</h2>
                    </div>
                    <div>
                        <label>E-mail</label>
                        <input type='email' value={email} onChange={(e) => setEmail(e.target.value)} />
                        <label>Heslo</label>
                        <input type='password' value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        <Link to={"/registrace"}>Nemáte účet?</Link>
                        <button type='button' type2="def" onClick={(e) => login(e)}>Přihlásit</button>
                    </div>
                </form>
            </div>
        </div>
    )
}