import { PageMenu, PageMenuAdditional, PageMenuBlock } from '../Components/PageMenu';
import { FaUser, FaUsers } from 'react-icons/fa';
import { MdCreate } from "react-icons/md"
import "./profile.css"
import { useState, useContext } from 'react';
import { UserContext } from '../Routes';
import { toast } from 'react-toastify';
import { APIFetch } from '../Helpers/apiFetch';
import Modal from 'react-modal';
import Switch from "react-switch";

export function Profile() {
    const { user, setUser } = useContext(UserContext)
    const [userTemp, setUserTemp] = useState({ firstName: user.firstName, lastName: user.lastName, email: user.email, telephone: user.telephone })
    const [companyTemp, setCompanyTemp] = useState({ companyName: "", adreas: "", ico: "", cisloOpravneni: "", osvc: false })
    const [osvcTemp, setOsvcTemp] = useState(false)
    const [page, setPage] = useState("profil")

    const changePage = (p) => {
        setPage(p)
    }

    const saveProfile = () => {
        setUser({ ...user, firstName: userTemp.firstName, lastName: userTemp.lastName, email: userTemp.email, telephone: userTemp.telephone })
        toast.success("Váš profil byl úspěšně uložen!")
    }

    const deleteCompany = (id) => {
        APIFetch("Profile/DeleteCompany", {
            companyId: id,
            token: user.tokens.api_token
        }).then(resp => {
            setUser({ ...user, companies: resp.Data })
            toast.success("Firma byla úspěšně smazána!")
        })
    }

    const createCompany = () => {
        if (user.companies.some(e => e.isOsvc === "true") && osvcTemp === true) {
            toast.error("Pouze jedna firmá může být OSVČ!")
        } else {
            if (companyTemp.companyName === "") {
                toast.info('Prosím zadejte hodnoty!')
            } else {
                APIFetch("Profile/CreateCompany", {
                    companyName: companyTemp.companyName,
                    ico: companyTemp.ico,
                    adresa: companyTemp.adresa,
                    cisloOpravneni: companyTemp.cisloOpravneni,
                    isOsvc: osvcTemp === true ? "true" : "false",
                    token: user.tokens.api_token
                }).then(resp => {
                    setUser({ ...user, companies: resp.Data })
                    closeCreateCompanyModal()
                    toast.success("Firma byla úspěšně přidána!")
                })
            }
        }
    }

    const updateCompany = () => {
        if (user.companies.some(e => e.isOsvc === "true") && osvcTemp === true) {
            toast.error("Pouze jedna firmá může být OSVČ!")
        } else {
            APIFetch("Profile/UpdateCompany", {
                companyId: companyTemp.companyId,
                companyName: companyTemp.companyName,
                ico: companyTemp.ico,
                adresa: companyTemp.adresa,
                cisloOpravneni: companyTemp.cisloOpravneni,
                isOsvc: osvcTemp,
                token: user.tokens.api_token
            }).then(resp => {
                toast.info("Firma byla upravena!")
                setUser({ ...user, companies: resp.Data })
                closeEditCompanyModal()
            })
        }
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            width: "500px",
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    const resetTempCompany = () => { setCompanyTemp({ companyName: "", adreas: "", ico: "", cisloOpravneni: "", osvc: "" }) }

    const [createCompanyModal, setCreateCompanyModal] = useState(false)
    const closeCreateCompanyModal = () => { setCreateCompanyModal(false) }
    const openCreateCompanyModal = () => {
        resetTempCompany()
        setOsvcTemp(false)
        setCreateCompanyModal(true)
    }

    const [editCompanyModal, setEditCompanyModal] = useState(false)
    const closeEditCompanyModal = () => { setEditCompanyModal(false) }
    const openEditCompanyModal = (company) => {
        setEditCompanyModal(true)
        setCompanyTemp(company)
    }

    const [infoSureBox, setInfoSureBox] = useState({ name: "" })

    const [isOpenSureBox, setIsOpenSureBox] = useState(false)
    const OpenSureBox = () => { setIsOpenSureBox(true) }
    const CloseSureBox = () => {
        setIsOpenSureBox(false)
    }
    const YesSureBox = () => {
        CloseSureBox()
        deleteCompany(infoSureBox.id)
    }


    return (
        <>
            <PageMenu>
                <PageMenuBlock tippy="Profil" onClick={() => changePage("profil")}><FaUser size={30} /></PageMenuBlock>
                <PageMenuBlock tippy="Firmy" onClick={() => changePage("firmy")}><FaUsers size={30} /></PageMenuBlock>
                {page === "firmy" && (
                    <PageMenuAdditional>
                        <PageMenuBlock tippy="Vytvořit firmu" onClick={openCreateCompanyModal}><MdCreate size={30} /></PageMenuBlock>
                    </PageMenuAdditional>
                )}
            </PageMenu>

            <Modal isOpen={isOpenSureBox}
                style={customStyles}
                onRequestClose={CloseSureBox}
            >
                <p>Vážně chcete smazat <b>{infoSureBox.name}</b>?</p>
                <div style={{ display: "flex", gap: "10px", alignItems: "center", justifyContent: "center", paddingTop: "10px" }}>
                    <button type2="def" onClick={YesSureBox}>ANO</button>
                    <button type2="def" onClick={CloseSureBox}>NE</button>
                </div>
            </Modal>

            {page === "profil" && (
                <div className='profile'>
                    <h1>Úprava profilu</h1>
                    <p>V této sekci lze upravit vaše údaje k profilu, která se automaticky doplní do formuláře a usnadní Vám tak tvorbu jakých koliv formulářů.</p>
                    <form>
                        <div className='inputs'>
                            <div className='input'>
                                <label>VAŠE KŘESTNÍ JMÉNO</label>
                                <input value={userTemp.firstName} onChange={(e) => setUserTemp({ ...userTemp, firstName: e.target.value })} />
                            </div>
                            <div className='input'>
                                <label>VAŠE PŘÍJMENÍ</label>
                                <input value={userTemp.lastName} onChange={(e) => setUserTemp({ ...userTemp, lastName: e.target.value })} />
                            </div>
                            <div className='input'>
                                <label>VÁŠ E-MAIL</label>
                                <input value={userTemp.email} onChange={(e) => setUserTemp({ ...userTemp, email: e.target.value })} />
                            </div>
                            <div className='input'>
                                <label>VAŠE TELEFONNÍ ČÍSLO</label>
                                <input value={userTemp.telephone} onChange={(e) => setUserTemp({ ...userTemp, telephone: e.target.value })} />
                            </div>
                            <div className='input'>
                                <label>ČÍSLO OSVĚDČENÍ</label>
                                <input disabled value={user.osvc} />
                            </div>
                        </div>

                        <div className='buttons'>
                            <button type='button' type2="def" onClick={saveProfile}>Uložit</button>
                        </div>
                    </form>
                </div>
            )}

            {page === "firmy" && (
                <div className='profile'>
                    <Modal isOpen={createCompanyModal}
                        style={customStyles}
                        onRequestClose={closeCreateCompanyModal}>
                        <h1>Vytvoření firmy</h1>
                        <form>
                            <div className='inputs'>
                                <div className='input'>
                                    <label>Název firmy</label>
                                    <input value={companyTemp.companyName} onChange={(e) => setCompanyTemp({ ...companyTemp, companyName: e.target.value })} />
                                </div>

                                <div className='input'>
                                    <label>Adresa firmy</label>
                                    <input value={companyTemp.adresa} onChange={(e) => setCompanyTemp({ ...companyTemp, adresa: e.target.value })} />
                                </div>

                                <div className='input'>
                                    <label>IČO firmy</label>
                                    <input value={companyTemp.ico} onChange={(e) => setCompanyTemp({ ...companyTemp, ico: e.target.value })} />
                                </div>

                                <div className='input'>
                                    <label>Číslo oprávnění</label>
                                    <input value={companyTemp.cisloOpravneni} onChange={(e) => setCompanyTemp({ ...companyTemp, cisloOpravneni: e.target.value })} />
                                </div>

                                <div className='input'>
                                    <label>OSVČ</label>
                                    <Switch checked={osvcTemp} onChange={
                                        (e) => {
                                            setOsvcTemp(e)
                                        }
                                    } />
                                </div>
                            </div>
                            <button type='button' type2="def" style={{ margin: "10px 0" }} onClick={createCompany}>Vytvořit</button>
                        </form>
                    </Modal>

                    <Modal isOpen={editCompanyModal}
                        style={customStyles}
                        onRequestClose={closeEditCompanyModal}>
                        <h1>Úprava firmy</h1>
                        <form>
                            <div className='inputs'>
                                <div className='input'>
                                    <label>Název firmy</label>
                                    <input value={companyTemp.companyName} onChange={(e) => setCompanyTemp({ ...companyTemp, companyName: e.target.value })} />
                                </div>

                                <div className='input'>
                                    <label>Adresa firmy</label>
                                    <input value={companyTemp.adresa} onChange={(e) => setCompanyTemp({ ...companyTemp, adresa: e.target.value })} />
                                </div>

                                <div className='input'>
                                    <label>IČO firmy</label>
                                    <input value={companyTemp.ico} onChange={(e) => setCompanyTemp({ ...companyTemp, ico: e.target.value })} />
                                </div>

                                <div className='input'>
                                    <label>Číslo oprávnění</label>
                                    <input value={companyTemp.cisloOpravneni} onChange={(e) => setCompanyTemp({ ...companyTemp, cisloOpravneni: e.target.value })} />
                                </div>

                                <div className='input'>
                                    <label>OSVČ</label>
                                    <Switch checked={osvcTemp} onChange={
                                        (e) => {
                                            setOsvcTemp(e)
                                        }
                                    } />
                                </div>
                            </div>
                            <button type='button' type2="def" style={{ margin: "10px 0" }} onClick={updateCompany}>Upravit</button>
                        </form>
                    </Modal>
                    <h1>Úprava firem</h1>
                    <p>V této sekci lze upravit vaše firmy v profilu, která se automaticky doplní do formuláře a usnadní Vám tak tvorbu jakých koliv formulářů.</p>
                    <table>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Název firmy</th>
                                <th>Adresa firmy</th>
                                <th>IČO firmy</th>
                                <th>Číslo oprávnění</th>
                                <th>Je OSVČ</th>
                                <th>Akce</th>
                            </tr>
                        </thead>
                        <tbody>
                            {user.companies.map((company, index) => (
                                <tr>
                                    <td>{index + 1}</td>
                                    <td>{company.companyName}</td>
                                    <td>{company.adresa}</td>
                                    <td>{company.ico}</td>
                                    <td>{company.cisloOpravneni}</td>
                                    <td>{company.isOsvc === "true" ? "Ano" : "Ne"}</td>
                                    <td>
                                        {/* <button type2="def" onClick={() => deleteCompany(company.companyId)}>Smazat</button> */}
                                        <button type2="def" onClick={() => {
                                            OpenSureBox()
                                            setInfoSureBox({ name: company.companyName, id: company.companyId })
                                        }}>Smazat</button>
                                        <button type2="def" onClick={() => openEditCompanyModal(company)}>Upravit</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                </div>
            )}


        </>
    )
}