import { useEffect, useState, useContext } from "react"
import { toast } from "react-toastify"
import { APIFetch } from "../../Helpers/apiFetch"
import { UserContext } from "../../Routes"
import { betterDate } from "../../Components/betterDate"
import { useNavigate, useParams } from "react-router-dom"
import { PageMenu, PageMenuBlock } from "../../Components/PageMenu"
import { Form } from "../../Editace/Form"
import { AiOutlineRollback } from 'react-icons/ai';
import { BsFillSendCheckFill, BsFillSendDashFill } from "react-icons/bs"
import betterState from "../../Components/betterState"

export function RevisionControl() {
    const { user, setUser } = useContext(UserContext)

    const [forms, setForms] = useState([])

    const navigate = useNavigate()

    const getForms = () => {
        APIFetch("Admin/Forms/GetAll", {
            state: "sended",
            token: user.tokens.api_token
        }).then(resp => {
            console.log(resp)
            setForms(resp.Data)
        })
    }

    useEffect(() => {
        getForms()
    }, [])

    const openRevision = (formId) => {
        navigate("/administrace/revize/" + formId)
    }

    const goBack = () => {
        navigate("/administrace/")
    }

    return (
        <>
            <PageMenu>
                <PageMenuBlock tippy="Zpátky" onClick={goBack}><AiOutlineRollback size={"30"} /></PageMenuBlock>
            </PageMenu>
            <h1>Administrace</h1>
            <h2>Kontrola revizí</h2>

            <table>
                <thead>
                    <tr>
                        <th>Revizní číslo</th>
                        <th>Typ Revize</th>
                        <th>Datum vytvoření</th>
                        <th>Datum poslání na kontrolu</th>
                        <th>Akce</th>
                    </tr>
                </thead>
                <tbody>
                    {forms.map(form => (
                        <tr>
                            <td>{form.revision_number}</td>
                            <td>{form.data.typZpravy}</td>
                            <td>{betterDate(form.created_at)}</td>
                            <td>{betterDate(form.sended_at)}</td>
                            <td>
                                <button type2="def" onClick={() => openRevision(form.data.formId)}>Otevřít</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    )
}

export function DisplayRevisionControl() {
    const { formId } = useParams();

    const { user } = useContext(UserContext)

    const [data, setData] = useState()

    const navigate = useNavigate()

    useEffect(() => {
        APIFetch("Forms/GetById", {
            revision_number: formId,
            token: user.tokens.api_token
        }).then(resp => {
            setData(resp.Data.data)
        })
    }, [])

    const goBack = () => {
        navigate("/administrace/revize")
    }

    const Potvrdit = () => {
        toast.success("Revize byla potvrzena!")
        goBack()
    }

    const Vratit = () => {
        toast.success("Revize byla vrácena na předělání!")
        APIFetch("Forms/ChangeState", {
            revision_number: data.formId,
            state: "returned",
            token: user.tokens.api_token
        })
        goBack()
    }

    if (!data) {
        return <div>Loading...</div>
    }



    return (
        <>
            <PageMenu>
                <PageMenuBlock tippy="Zpátky" onClick={goBack}><AiOutlineRollback size={"30"} /></PageMenuBlock>
                <PageMenuBlock tippy="Potvrdit" onClick={Potvrdit}><BsFillSendCheckFill size={"30"} /></PageMenuBlock>
                <PageMenuBlock tippy="Vrátit" onClick={Vratit}><BsFillSendDashFill size={"30"} /></PageMenuBlock>
            </PageMenu>

            <Form data={data} setData={setData} revisionType={data.typZpravy} disabled={true}/>

        </>
    )
}