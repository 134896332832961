import { useState, useRef, useEffect } from 'react';
import { PageMenu, PageMenuBlock } from '../Components/PageMenu';
import { FaPrint, FaSave, FaTrash } from 'react-icons/fa';
import { BsArrowRightSquare } from "react-icons/bs"
import { AiFillFileText, AiOutlineRollback } from "react-icons/ai"
import { UserContext } from '../Routes';
import { useContext } from 'react';
import { Form } from './Form';
import { RevisionSelector } from './RevisionSelector';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';


import { useReactToPrint } from 'react-to-print';
import { APIFetch } from '../Helpers/apiFetch';

export function Revision() {
  const { user, setUser } = useContext(UserContext)

  const [revisionType, setRevisionType] = useState("vychozi")
  const [RevisionSelectorForm, setRevisionSelectorForm] = useState(<></>)
  const [formData, setFormData] = useState(
    {
      rozvodneSoustavy: [],
      zdrojeMN: [],
      ochranneOpatreni: [],
      prostredkyZakladniOchrany: [],
      prostredkyOchranyPriPoruse: [],
      doplnovkaOchrana: [],
      podkladyProRevizi: [],
      prostoryProstredi: [],
      trvalePripojenePredmety: [],
      trvalePripojenePredmetyText: [{ value: "", select: "" }, { value: "", select: "" }, { value: "", select: "" }, { value: "", select: "" }],
      oznaceniNulovychOchrannychVodicu: [],
      vybaveniSchematy: [{ value: "", select: "" }, { value: "", select: "" }, { value: "", select: "" }, { value: "", select: "" }, { value: "", select: "" }, { value: "", select: "" }, { value: "", select: "" }, { value: "", select: "" }, { value: "", select: "" }, { select1: "", value: "", select2: "" }, { poznamka: "" }, { value: "", select: "" }, { value: "", select: "" }, { value: "", select: "" }, { value: "", select: "" }, { value: "", select: "" }, { value: "", select: "" }, { value: "", select: "" }, { value: "", select: "" }, { value: "", select: "" }, { value: "", select: "" }, { value: "", select: "" }, { value: "", select: "" }, { value: "", select: "" }, { value: "", select: "" }, { value: "", select: "" },],
      zarizeni: [],
      svitidla: [],
      motory: [],
      instalace: [],
      instalaceTopneSystemy: [],
      instalaceMotory: [],
      prostredi: [],
      zkousky: {},
      prilohy: [],
      formId: "...",
      revizniTechnik: { companyName: "", ico: "", cisloOpravneni: "", firstName: "", lastName: "", osvc: "" }
    })

  useEffect(() => {
    setFormData({ ...formData, typZpravy: revisionType })
  }, [revisionType])

  const [isRevisionCreated, setIsRevisionCreated] = useState(false)

  const createRevision = () => {
    setIsRevisionCreated(true)
    setUser({ ...user, isEditing: true })

    APIFetch("Forms/GetId", {
      token: user.tokens.api_token
    }).then(resp => {
      setFormData({ ...formData, formId: resp.Data })
    })
  }

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleSave = () => {
    APIFetch("Forms/Save", {
      revision_number: formData.formId,
      state: "in_progress",
      data: JSON.stringify(formData),
      token: user.tokens.api_token
    }).then(resp => {
      console.log(resp)
      toast.success("Úspěšně uloženo")
    })
    setUser({ ...user, isEditing: false })
  }

  const navigate = useNavigate()

  const openRevisions = () => {
    navigate("/dashboard/revize")
  }


  return (
    <>

      {isRevisionCreated === false ? (
        <PageMenu title="Editace" big center>
          <PageMenuBlock onClick={createRevision}>Vytvořit novou revizi</PageMenuBlock>
          <PageMenuBlock onClick={openRevisions}>Otevřít revizi</PageMenuBlock>
        </PageMenu>
      ) : (
        <>
          <PageMenu>
            <PageMenuBlock tippy="Uložit" onClick={handleSave}><FaSave size={"30"} /></PageMenuBlock>
            <PageMenuBlock tippy="Vytisknout" onClick={handlePrint}><FaPrint size={"30"} /></PageMenuBlock>
            {user.role === "admin" && <PageMenuBlock tippy="Testovací data" onClick={() => {
              document.querySelectorAll('input[type="text"]').forEach((el, index) => {
                if(index !== 0) el.value = "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Integer in sapien. Etiam ligula pede, sagittis quis, interdum ultricies, scelerisque eu."
              })
              document.querySelectorAll('textarea').forEach(el => {
                el.value = "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Integer in sapien. Etiam ligula pede, sagittis quis, interdum ultricies, scelerisque eu."
              })
              document.querySelectorAll('select').forEach(el => {
                const random = parseInt(Math.random() * el.options.length)
                const randomOpt = el.options[random]
                el.value = randomOpt.innerText
              })
            }}><AiFillFileText size={"30"} /></PageMenuBlock>}
          </PageMenu>

          <div style={{ padding: "10px", margin: "0 auto", textAlign: "center" }}>
            <RevisionSelector value={revisionType} setValue={setRevisionType} />
          </div>

          <div ref={componentRef}>
            <Form revisionType={revisionType} data={formData} setData={setFormData} />
          </div>

        </>
      )}
    </>
  )
}

export function EditRevision() {
  const { formId } = useParams();
  const { user, setUser } = useContext(UserContext)
  const [data, setData] = useState()

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    APIFetch("Forms/GetById", {
      revision_number: formId,
      token: user.tokens.api_token
    }).then(resp => {
      setData(resp.Data.data)
    })
  }, [])

  const navigate = useNavigate()

  const handleSave = () => {
    APIFetch("Forms/Save", {
      revision_number: data.formId,
      state: "in_progress",
      data: JSON.stringify(data),
      token: user.tokens.api_token
    }).then(resp => {
      toast.success("Úspěšně uloženo")
    })
    setUser({ ...user, isEditing: false })
  }

  const odeslat = () => {
    if (user.isEditing) {
      toast.error("Než budete moci revizi odeslat si jí prvně uložte!")
    } else {
      APIFetch("Forms/ChangeState", {
        revision_number: data.formId,
        state: "sended",
        token: user.tokens.api_token
      }).then(resp => {
        toast.success("Revize byla odeslána!")
        navigate("/dashboard/revize")
        setUser({ ...user, isEditing: false })
      })
    }
  }

  const goBack = () => {
    if(user.isEditing !== true) {
      navigate("/dashboard/revize")
    } else {
      toast.error("Prosím uložte si prvně práci!")
    }
  }

  if (!data) {
    return <div>Loading...</div>
  }

  return (
    <>
      <PageMenu>
        <PageMenuBlock tippy="Zpátky" onClick={goBack}><AiOutlineRollback size={"30"} /></PageMenuBlock>
        <PageMenuBlock tippy="Uložit" onClick={handleSave}><FaSave size={"30"} /></PageMenuBlock>
        <PageMenuBlock tippy="Vytisknout" onClick={handlePrint}><FaPrint size={"30"} /></PageMenuBlock>
        <PageMenuBlock tippy="Odeslat" onClick={odeslat}><BsArrowRightSquare size={"30"} /></PageMenuBlock>
      </PageMenu>

      <div ref={componentRef}>
        <Form revisionType={data.typZpravy} data={data} setData={setData} />
      </div>
    </>
  )

}