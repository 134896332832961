import { useContext, useState } from "react"
import { UserContext } from "../Routes"
import { Link, Navigate, useNavigate } from "react-router-dom"
import logo from "../images/logo.png"
import Modal from "react-modal"
import { toast } from "react-toastify"

export function Dashboard({ children }) {
    const { user, setUser } = useContext(UserContext)
    const [PageThatUserWantsToVisit, SetPageThatUserWantsToVisit] = useState("")
    const navigate = useNavigate()

    const HandleLink = (e, title) => {
        if (user && user.isEditing) {
            e.preventDefault()
            SetPageThatUserWantsToVisit(e)
            OpenSureBox()
        } else {
            document.title = title
            ChangeClassLink(e)     
        }
    }

    const ChangeClassLink = (e) => {
        const list = document.querySelectorAll(".leftmenu a")
        list.forEach(el => {
            if (el === e.target) {
                el.classList.add("active")
            } else {
                el.classList.remove("active")
            }
        })
    }

    const logout = () => {
        setUser()
        window.location = "/"
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            width: "500px",
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };
    const [isOpenSureBox, setIsOpenSureBox] = useState(false)
    const OpenSureBox = () => { setIsOpenSureBox(true) }
    const CloseSureBox = () => {
        setIsOpenSureBox(false)
        SetPageThatUserWantsToVisit("")
    }
    const YesSureBox = () => {
        navigate(PageThatUserWantsToVisit.target.getAttribute("href"))
        ChangeClassLink(PageThatUserWantsToVisit)
        SetPageThatUserWantsToVisit("")
        setUser({...user, isEditing: false})
        CloseSureBox()
    }

    return (
        <>
        <Modal isOpen={isOpenSureBox}
        style={customStyles}
        onRequestClose={CloseSureBox}
        >
            <p>Jste si jisti že vážně chcete odejít bez uložení?</p>
            <div style={{display: "flex", gap: "10px", alignItems: "center", justifyContent: "center", paddingTop: "10px"}}>
                <button type2="def" onClick={YesSureBox}>ANO</button>
                <button type2="def" onClick={CloseSureBox}>NE</button>
            </div>
        </Modal>
            {user ? (
                <>
                    <header>
                        <img src={logo} width="200px" />
                    </header>
                    <div className="header-border"></div>
                    <main>
                        <div className="leftmenu">
                            <div className="links">
                                <Link to={"/dashboard/editace"} onClick={(e) => HandleLink(e, "Editace")}>Editace</Link>
                                <Link to={"/dashboard/revize"} onClick={(e) => HandleLink(e, "Revize")}>Revize</Link>
                            </div>
                            <div className="links">
                                {/* <Link onClick={() => {
                                    console.log(user)
                                }}>print user</Link> */}
                                
                                {user.role === "admin" && (
                                    <Link to={"/administrace"} onClick={(e) => HandleLink(e, "Administrace")}>Administrace</Link>
                                )}
                                <Link to={"/profil/uzivatel"} onClick={(e) => HandleLink(e, "Profil")}>Profil</Link>
                                <Link onClick={logout}>Odhlásit se</Link>
                            </div>
                        </div>
                        <div id="displayedPage">
                            {children}
                        </div>
                    </main>
                </>
            ) : (
                <Navigate to={"/login"} />
            )}
        </>
    )
}